import { useTheme } from '@mui/styles'
import { LocaleManager as BryntumLocaleManager } from 'bryntum-scheduler'
import { useEffect } from 'react'
import { Layout, useLocaleState, useSidebarState } from 'react-admin'

import CustomAppBar from './AppBar'
import CustomMenu from './Menu'
import { useCurrentAccountSelectors } from '../resources/accounts/hooks'
import bryntumLocales from '../vendor/bryntum/locales'

export default (props) => {
  const [sidebarIsOpen] = useSidebarState()
  const outerElementsWidth = sidebarIsOpen ? 266 : 76
  const theme = useTheme()

  const [locale] = useLocaleState()
  useEffect(() => {
    if (locale) BryntumLocaleManager.applyLocale(bryntumLocales[locale])
  }, [locale])

  // Trigger a fetch of organisations and hubs to load cache before the first render
  // This ensures that useCurrentAccountSelectors hook will directly return the correct values everywhere
  const { hasSingleOrganisation, hasSingleHub } = useCurrentAccountSelectors()
  if (hasSingleOrganisation === undefined || hasSingleHub === undefined) {
    return null
  }

  return (
    <Layout
      {...props}
      theme={theme}
      appBar={CustomAppBar}
      menu={CustomMenu}
      sx={{
        minWidth: 'inherit',
        overflow: 'scroll',
        '& .RaLayout-content': {
          maxWidth: `calc(100% - ${outerElementsWidth}px)`,
          padding: '5px 10px 60px 5px',
          marginLeft: '5px',
        },
      }}
    />
  )
}
