import { Tooltip, Typography } from '@mui/material'
import { useTranslate } from 'ra-core'

const LargeTooltip = ({ children, hidden = false, title, ...props }) => {
  const translate = useTranslate()
  return (
    <Tooltip {...props} title={hidden ? false : <Typography sx={{ fontSize: '12px' }}>{translate(title)}</Typography>}>
      {children}
    </Tooltip>
  )
}

export default LargeTooltip
