import { useEffect } from 'react'
import { UPDATE, useLocaleState, usePermissions, useResourceContext } from 'react-admin'

import config from './config'
import { useApi } from '../../api/apiProvider'
import { SYSTEM_PERMISSION_READ, SYSTEM_PERMISSION_UPDATE } from '../../config/permissions'
import { isInputPermissionDenied, useResourcePermissions } from '../../domain/permissions'

export const useGetBookingsJustifications = () => {
  const [locale] = useLocaleState()
  const [hasRead] = useResourcePermissions(config.name, SYSTEM_PERMISSION_READ)
  const [fetchBookingsJustifications, { data: bookingsJustifications }] = useApi('/bookings/justifications', {
    method: 'GET',
    params: { locale },
  })
  useEffect(() => {
    if (hasRead) fetchBookingsJustifications()
  }, [fetchBookingsJustifications, hasRead])
  return bookingsJustifications
}

export const useCanEditBookings = () => {
  const [hasEdit] = useResourcePermissions(config.name, SYSTEM_PERMISSION_UPDATE)
  const { permissions } = usePermissions()
  if (!hasEdit) return false

  const commonProps = { resource: config.name, permissions, formType: UPDATE }
  const isStartRestricted = isInputPermissionDenied({ ...commonProps, inputSource: 'start_scheduled_on' })
  const isEndRestricted = isInputPermissionDenied({ ...commonProps, inputSource: 'end_scheduled_on' })
  return !isStartRestricted && !isEndRestricted
}
