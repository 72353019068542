import TimelineIcon from '@mui/icons-material/EventNote'
import DefaultIcon from '@mui/icons-material/ViewList'
import { Badge, Box } from '@mui/material'
import { useTranslate } from 'ra-core'
import { MenuItemLink, usePermissions, useResourceDefinitions, useSidebarState } from 'react-admin'

import { useAccounts } from '../api/accountsProvider'
import { SYSTEM_PERMISSION_READ, UI_PERMISSION_MENU } from '../config/permissions'
import { hasResourcePermission, useResourcePermissions } from '../domain/permissions'
import vehiclesConfig from '../resources/vehicles/config'
import { TIMELINE_KEY, VehiclesTimelinePage } from '../resources/vehicles/timeline'

const Menu = () => {
  const { currentAccount } = useAccounts()
  const [sidebarIsOpen] = useSidebarState()
  const translate = useTranslate()

  const resourcesDefinitions = useResourceDefinitions()
  const resources = Object.keys(resourcesDefinitions).map((name) => resourcesDefinitions[name])
  const { permissions } = usePermissions()
  const displayedResources = resources.filter((resource) =>
    hasResourcePermission(permissions, resource.name, SYSTEM_PERMISSION_READ, UI_PERMISSION_MENU),
  )
  const [hasReadForVehicles] = useResourcePermissions(vehiclesConfig.name, SYSTEM_PERMISSION_READ)

  const getMenuItemLinkCommonProps = (name) => ({
    primaryText: sidebarIsOpen ? name : '',
    sx: { marginBottom: '5px', paddingRight: sidebarIsOpen ? undefined : 0 },
    tooltipProps: { title: name },
  })

  return (
    <Box mt={1}>
      {hasReadForVehicles && (
        <MenuItemLink
          {...getMenuItemLinkCommonProps(translate('mymove.scheduler.name'))}
          key={TIMELINE_KEY}
          leftIcon={<TimelineIcon />}
          to={VehiclesTimelinePage.path}
        />
      )}
      {displayedResources.map((resource) => {
        let icon = resource.icon ? <resource.icon /> : <DefaultIcon />
        if (currentAccount && resource.options?.badge) {
          icon = (
            <Badge badgeContent={currentAccount.counts[resource.name]} color="error" overlap="rectangular">
              {icon}
            </Badge>
          )
        }
        return (
          <MenuItemLink
            {...getMenuItemLinkCommonProps(translate(`resources.${resource.name}.name`, 2))}
            key={resource.name}
            leftIcon={icon}
            to={`/${resource.name}`}
          />
        )
      })}
    </Box>
  )
}

export default Menu
