import enLocale from 'bryntum-scheduler/locales/scheduler.locale.En'
import frLocale from 'bryntum-scheduler/locales/scheduler.locale.FrFr'
import nlLocale from 'bryntum-scheduler/locales/scheduler.locale.Nl'

const commonDateHelperSettings = {
  weekStartDay: 1,
  parsers: { L: 'DD/MM/YYYY', LT: 'HH:mm', LTS: 'HH:mm:ss' },
}

const englishLocaleCode = 'en-BE'
const englishLocale = {
  ...enLocale,
  localeName: 'EnBe',
  localeDesc: 'English (BEL)',
  localeCode: englishLocaleCode,
  DateHelper: {
    ...enLocale.DateHelper,
    ...commonDateHelperSettings,
    locale: englishLocaleCode,
  },
}

const frenchLocaleCode = 'fr-BE'
const frenchLocale = {
  ...frLocale,
  localeName: 'FrBe',
  localeDesc: 'French (BEL)',
  localeCode: frenchLocaleCode,
  DateHelper: {
    ...frLocale.DateHelper,
    ...commonDateHelperSettings,
    locale: frenchLocaleCode,
  },
}

const dutchLocaleCode = 'nl-BE'
const dutchLocale = {
  ...nlLocale,
  localeName: 'NlBe',
  localeDesc: 'Dutch (BEL)',
  localeCode: dutchLocaleCode,
  DateHelper: {
    ...nlLocale.DateHelper,
    ...commonDateHelperSettings,
    locale: dutchLocaleCode,
  },
}

const bryntumLocales = { en: englishLocale, fr: frenchLocale, nl: dutchLocale }

export default bryntumLocales
