import { Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslate } from 'ra-core'
import { useState } from 'react'
import { Confirm, useRecordContext, useResourceContext } from 'react-admin'

import { getResourceByName } from '../resources'
import { useRunResourceAction } from '../resources/common/hooks'
import { useSmallScreen } from '../utils/theme'

const useStyles = makeStyles({
  icon: {
    marginRight: '0.35em',
  },
})

const funcOrValue = (object, ...args) => (typeof object === 'function' ? object(...args) : object)

const ActionButton = ({ action, children, ...props }) => {
  const record = useRecordContext()
  const classes = useStyles()
  const isSmallScreen = useSmallScreen()
  const resource = useResourceContext()
  const translate = useTranslate()

  const resourceConfig = getResourceByName(resource)
  const actionConfig = resourceConfig.options.actions?.[action]
  const actionTranslationBase = `resources.${resource}.actions.${actionConfig?.name}`
  const actionLabel = `${actionTranslationBase}.` + (isSmallScreen ? 'shortLabel' : 'label')
  const confirm = funcOrValue(actionConfig?.confirm, { record })
  const Icon = actionConfig?.icon

  const [isPristine, setIsPristine] = useState(true)
  const [confirmData, setConfirmData] = useState({})
  const [error, setError] = useState(null)

  const update = (data) => {
    const newState = { ...confirmData, ...data }
    setConfirmData(newState)
    setError(confirm?.validate?.(newState))
  }

  const [isConfirmOpen, setConfirmOpen] = useState(false)
  const [runAction, isRunningAction] = useRunResourceAction({
    action,
    actionName: actionConfig?.name,
    data: confirmData,
    id: record.id,
    onSuccess: () => setConfirmOpen(false),
  })

  const handleClick = () => {
    if (confirm) {
      setConfirmData({})
      setIsPristine(true)
      setConfirmOpen(true)
    } else {
      runAction()
    }
  }
  const handleClose = () => setConfirmOpen(false)
  const handleConfirm = () => {
    setIsPristine(false)
    if (confirm.validate) {
      const error = confirm.validate(confirmData)
      if (error) {
        setError(error)
        return
      }
    }
    runAction()
  }

  return (
    <>
      <Button {...props} color="primary" variant="contained" onClick={handleClick} disabled={isRunningAction}>
        {Icon && <Icon className={classes.icon} />}
        {translate(actionLabel)}
      </Button>
      {confirm && (
        <Confirm
          cancel={confirm.cancelLabel}
          confirm={confirm.confirmLabel}
          confirmColor={confirm.confirmColor}
          content={confirm.content?.({ update, state: confirmData, isPristine, error })}
          isOpen={isConfirmOpen}
          loading={isRunningAction}
          onClose={handleClose}
          onConfirm={handleConfirm}
          title={actionConfig?.label}
        />
      )}
    </>
  )
}

export default ActionButton
