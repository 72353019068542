import NoLocationIcon from '@mui/icons-material/WrongLocation'
import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { DATETIME_SHORT_WITH_SECONDS } from 'luxon/src/impl/formats'
import { useTranslate } from 'ra-core'
import React, { useMemo } from 'react'
import { Filter, isEmpty, List, LoadingPage, useGetMany, useGetRecordRepresentation, useListContext } from 'react-admin'

import config from './config'
import AddressInputMain from '../../components/AddressInputMain'
import { AdvancedDatesFilter } from '../../components/AdvancedDatesFilter'
import { path as gpsFixedIconPath } from '../../components/icons/GpsFixedIcon'
import { path as sideCarIconPath } from '../../components/icons/SideCarIcon'
import Map, { getFormattedPosition, getMapCircle, getMapMarker } from '../../components/Map'
import { VEHICLE_TYPE_CAR, VEHICLE_TYPES_ICONS } from '../../config/vehicles'
import { formatDate, getRoundedNowJS } from '../../utils/dates'
import HubsFilter from '../hubs/filter'
import OrganisationsFilter from '../organisations/filter'
import vehiclesConfig from '../vehicles/config'
import VehiclesFilter from '../vehicles/filter'

const hasValidAddressFilter = (filterValues) => Boolean(filterValues.lat && filterValues.lng && filterValues.next_to)

const useStyles = makeStyles((theme) => ({
  addressInputFilter: {
    width: 227,
  },
  noLocationIcon: {
    fontSize: 50,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
  },
}))

const EmptyTelematicsBox = ({ isListView }) => {
  const classes = useStyles()
  const translate = useTranslate()
  return (
    <Box justifyContent="center" alignItems="center" textAlign="center" p={3} pt={isListView ? 3 : 1.5}>
      <NoLocationIcon className={classes.noLocationIcon} />
      <Typography variant="h6" color="textSecondary" gutterBottom>
        {translate('resources.vehicle-telematics.list.empty')}
      </Typography>
    </Box>
  )
}

export const VehicleTelematicsFilters = ({ isListView = true, ...props }) => {
  const { filterValues } = useListContext()
  const classes = useStyles()
  return (
    <Filter {...props}>
      <AddressInputMain
        alwaysOn
        className={classes.addressInputFilter}
        latLngSources={['lat', 'lng']}
        linkedSourcesOnClear={isListView ? ['timeline_start', 'timeline_end'] : null}
        source="next_to"
      />
      {isListView && <OrganisationsFilter alwaysOn />}
      {isListView && <HubsFilter alwaysOn />}
      {isListView && <VehiclesFilter alwaysOn />}
      {(!isListView || hasValidAddressFilter(filterValues)) && (
        <AdvancedDatesFilter maxDate={getRoundedNowJS()} maxDurationInDays={7} alwaysOn />
      )}
    </Filter>
  )
}

export const VehicleTelematicsMapView = ({ height = 700, isListView = true }) => {
  const { data: vehicleTelematics, isLoading, filterValues } = useListContext()

  const vehicleIds = useMemo(() => {
    return Array.from(new Set(vehicleTelematics?.map((entry) => entry.vehicle_id) || []))
  }, [JSON.stringify(vehicleTelematics)]) // eslint-disable-line react-hooks/exhaustive-deps
  const { data: vehicles } = useGetMany(vehiclesConfig.name, { ids: vehicleIds }, { enabled: !isEmpty(vehicleIds) })

  const getVehicleRecordRepresentation = useGetRecordRepresentation(vehiclesConfig.name)

  const mapInfo = useMemo(() => {
    const vehicleMarkers = {}
    const vehiclePolylines = {}
    const searchCircle = []

    if (vehicleTelematics && vehicles) {
      const groupedData = Object.groupBy(vehicleTelematics, (entry) => entry.vehicle_id)
      Object.entries(groupedData).forEach(([vehicleId, vehicleData]) => {
        const vehicle = vehicles.find((v) => v.id === vehicleId)
        const recordRepresentation = getVehicleRecordRepresentation(vehicle)

        vehicleData.forEach((data, index) => {
          if (!vehicleMarkers[vehicleId]) {
            vehicleMarkers[vehicleId] = []
            vehiclePolylines[vehicleId] = []
          }

          const iconPath =
            vehicle?.type === VEHICLE_TYPE_CAR ? sideCarIconPath : VEHICLE_TYPES_ICONS[vehicle?.type]?.path
          const reverse = index > 0 && vehicleData[index - 1].position.longitude < data.position.longitude

          vehicleMarkers[vehicleId].push(
            getMapMarker(
              data.position,
              iconPath,
              {
                name: recordRepresentation,
                description: formatDate(data.captured_on, DATETIME_SHORT_WITH_SECONDS),
              },
              reverse,
            ),
          )
          vehiclePolylines[vehicleId].push(getFormattedPosition(data.position))
        })
      })
      if (hasValidAddressFilter(filterValues)) {
        const searchPosition = getFormattedPosition(filterValues)
        searchCircle.push(getMapCircle(1000, searchPosition))
        vehicleMarkers['search_position'] = getMapMarker(searchPosition, gpsFixedIconPath, {
          name: filterValues.next_to.formatted_address,
        })
      }
    }

    return { vehicleMarkers, vehiclePolylines, searchCircle }
  }, [JSON.stringify(vehicleTelematics), JSON.stringify(vehicles), JSON.stringify(filterValues)]) // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading) {
    return <LoadingPage />
  }

  if (isEmpty(vehicleTelematics)) {
    return <EmptyTelematicsBox isListView={isListView} />
  }

  return (
    <Map
      markers={Object.values(mapInfo.vehicleMarkers).flat()}
      polylines={Object.values(mapInfo.vehiclePolylines)}
      circles={mapInfo.searchCircle}
      height={height}
    />
  )
}

export default () => (
  <List
    actions={null}
    empty={false}
    filters={<VehicleTelematicsFilters />}
    pagination={null}
    perPage={1000}
    sort={config.options.defaultSort}
  >
    <VehicleTelematicsMapView />
  </List>
)
