import { Box, InputAdornment, Typography } from '@mui/material'
import { Form, useTranslate } from 'ra-core'
import { useEffect } from 'react'
import { minValue, NumberInput, useRecordContext } from 'react-admin'

import AdvancedDateTimeInput, { DATETIME_INPUT_MODE_DATE } from '../../components/AdvancedDateTimeInput'
import { getDateFromNow } from '../../utils/dates'

const PlanNextMaintenanceConfirmContent = ({ state, update, isPristine, error }) => {
  const translate = useTranslate()

  const record = useRecordContext()

  useEffect(() => {
    update({
      next_maintenance_mileage: record.next_maintenance_mileage,
      next_maintenance_date: record.next_maintenance_date,
    })
  }, [record]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box>
      <Typography sx={{ textAlign: 'justify', mb: 2 }}>
        {translate('resources.vehicles.actions.plan_next_maintenance.helperText')}
      </Typography>

      <Form mode="onBlur">
        <Box>
          <NumberInput
            source="next_maintenance_mileage"
            min={0}
            validate={minValue(0)}
            InputProps={{ endAdornment: <InputAdornment position="end">km</InputAdornment> }}
            onChange={(e) =>
              update({ next_maintenance_mileage: e.target.value === '' ? null : Number(e.target.value) })
            }
          />
          <AdvancedDateTimeInput
            source="next_maintenance_date"
            minDate={getDateFromNow({ day: 1 })}
            mode={DATETIME_INPUT_MODE_DATE}
            onChange={(e) => update({ next_maintenance_date: e })}
          />
        </Box>
      </Form>
    </Box>
  )
}

export const PlanNextMaintenanceConfirm = ({ record }) => ({
  content: (props) => <PlanNextMaintenanceConfirmContent {...props} />,
  confirmColor: 'primary',
})
