import { InputAdornment } from '@mui/material'
import classnames from 'classnames'
import { get, pick } from 'lodash'
import { useTranslate } from 'ra-core'
import { useEffect, useRef, useState } from 'react'
import {
  CREATE,
  FormDataConsumer,
  ImageField,
  ImageInput,
  maxValue,
  minValue,
  number,
  NumberInput,
  required,
  SelectInput,
  TextInput,
  UPDATE,
  useGetList,
  useGetOne,
  useListContext,
  useRecordContext,
} from 'react-admin'
import { useFormContext } from 'react-hook-form'

import AdvancedDateTimeInput from '../../components/AdvancedDateTimeInput'
import AdvancedSelectInput from '../../components/AdvancedSelectInput'
import BasicFormToolbar from '../../components/BasicFormToolbar'
import env from '../../config/env'
import { SYSTEM_PERMISSION_READ } from '../../config/permissions'
import { useCommonStyles } from '../../config/theme'
import {
  VEHICLE_ENERGY_TYPES,
  VEHICLE_GATEWAYS,
  VEHICLE_SPECIAL_FIELD_CODE_CHARGING_INSTRUCTIONS,
  VEHICLE_SPECIAL_FIELD_CODE_FUEL_CARD_PIN,
  VEHICLE_SPECIAL_FIELD_CODE_FUELING_INFO,
  VEHICLE_SPECIAL_FIELD_CODE_PARKING_SPOT,
  VEHICLE_SPECIAL_FIELDS,
  VEHICLE_TRANSMISSION_TYPES,
  VEHICLE_TYPE_CAR_BLUEPRINT_SETS,
  VEHICLE_TYPES,
} from '../../config/vehicles'
import { useResourcePermissions } from '../../domain/permissions'
import { isVehicleCar, isVehicleElectricCar, isVehicleFuelCar } from '../../domain/vehicles'
import { getRoundedNowJS, parseDateAsDateTime, validateEndDateAfterStartDate } from '../../utils/dates'
import { useSmallScreen } from '../../utils/theme'
import { validateVIN } from '../../utils/validators'
import bookingConfig from '../bookings/config'
import { FormDivider } from '../common'
import { AdvancedSimpleForm, FormSubTitle } from '../common/forms'
import HubReferenceInput from '../hubs/input'
import organisationsConfig from '../organisations/config'
import OrganisationReferenceInput from '../organisations/input'
import vehicleUnavailabilitiesConfig from '../vehicleUnavailabilities/config'

const vehicleTypeChoices = Object.entries(VEHICLE_TYPES).map(([k, v]) => ({ id: k, name: v }))
const vehicleBlueprintSetChoices = Object.entries(VEHICLE_TYPE_CAR_BLUEPRINT_SETS).map(([k, v]) => ({ id: k, name: v }))
const vehicleEnergyTypeChoices = Object.entries(VEHICLE_ENERGY_TYPES).map(([k, v]) => ({ id: k, name: v }))
const vehicleTransmissionTypeChoices = Object.entries(VEHICLE_TRANSMISSION_TYPES).map(([k, v]) => ({ id: k, name: v }))
const vehicleGatewayCodeChoices = Object.entries(VEHICLE_GATEWAYS).map(([k, v]) => ({ id: k, name: v }))

const validateValue = [number('mymove.validation.number.invalid'), minValue(0, 'mymove.validation.number.notNegative')]

const validateNumberOfSeats = [
  number('mymove.validation.number.invalid'),
  minValue(1, 'mymove.validation.number.positive'),
]

const validateVolume = [number('mymove.validation.number.invalid'), minValue(1, 'mymove.validation.number.positive')]

const validateMaximumAutonomy = [
  required(),
  number('mymove.validation.number.invalid'),
  minValue(0, 'mymove.validation.number.notNegative'),
]

const validateTankSize = [
  required(),
  number('mymove.validation.number.invalid'),
  minValue(0, 'mymove.validation.number.notNegative'),
]

const validateAverageConsumption = [
  required(),
  number('mymove.validation.number.invalid'),
  minValue(0, 'mymove.validation.number.notNegative'),
]

const validatePriceFactor = [
  required(),
  number('mymove.validation.number.invalid'),
  minValue(0, 'mymove.validation.number.notNegative'),
]

const validateSecurityDepositAmount = [
  number('mymove.validation.number.invalid'),
  minValue(0, 'mymove.validation.number.notNegative'),
]

const validateChargingBuffer = [
  number('mymove.validation.number.invalid'),
  minValue(0, 'resources.vehicles.forms.validation.charging_buffer.notInRange'),
  maxValue(24 * 60, 'resources.vehicles.forms.validation.charging_buffer.notInRange'),
]

const validatePricePerKm = [
  number('mymove.validation.number.invalid'),
  minValue(0, 'mymove.validation.number.notNegative'),
]

const VehicleSpecialFieldInput = ({ code, ...props }) => (
  <TextInput {...props} source={'details_object.' + code} label={VEHICLE_SPECIAL_FIELDS[code]} />
)

const PricePerKmNumberInput = ({ organisationId, source, type, ...props }) => {
  const { setValue } = useFormContext()

  const [hasReadForOrganisations] = useResourcePermissions(organisationsConfig.name, SYSTEM_PERMISSION_READ)
  const { data: organisation } = useGetOne(
    organisationsConfig.name,
    { id: organisationId },
    { enabled: hasReadForOrganisations && Boolean(organisationId) },
  )
  const organisationPricePerKm = get(organisation, 'price_per_km', null)
  const [pricePerKm, setPricePerKm] = useState(organisationPricePerKm)

  useEffect(() => {
    setPricePerKm(organisationPricePerKm)
  }, [organisationId, organisationPricePerKm])

  useEffect(() => {
    if (type === CREATE) {
      setValue(source, pricePerKm)
    }
  }, [setValue, type, pricePerKm, source])

  return (
    <NumberInput
      source={source}
      validate={validatePricePerKm}
      format={(v) => (v ? v / 100 : null)}
      parse={(v) => v * 100}
      step={0.01}
      min={0}
      InputProps={{ endAdornment: <InputAdornment position="end">{env.CURRENCY_SYMBOL}</InputAdornment> }}
      label={`resources.organisations.fields.${source}`}
      {...props}
    />
  )
}

const getFieldCollectionFromObject = (obj) =>
  obj
    ? Object.entries(obj)
        .map(([k, v]) => ({ code: k, value: v }))
        .filter((i) => i.value !== null && i.value !== undefined) // remove items with no value
    : undefined

const getFieldObjectFromCollection = (coll) =>
  coll && Array.isArray(coll) ? coll.reduce((acc, field) => ({ ...acc, [field.code]: field.value }), {}) : coll

export const transformValues = ({
  charging_buffer,
  details_object,
  information_document_data,
  price_per_km,
  security_deposit_amount,
  ...restValues
}) =>
  restValues
    ? {
        ...restValues,
        charging_buffer: charging_buffer && isVehicleElectricCar(restValues) ? charging_buffer : 0,
        details: getFieldCollectionFromObject(details_object),
        information_document_data:
          information_document_data && isVehicleCar(restValues) ? information_document_data : null,
        price_per_km: price_per_km && isVehicleCar(restValues) ? price_per_km : 0,
        security_deposit_amount: security_deposit_amount ?? 0,
      }
    : restValues

const VehicleFormLayout = ({ type = UPDATE }) => {
  const { filterValues } = useListContext()
  const record = useRecordContext()
  const translate = useTranslate()

  const isSmallScreen = useSmallScreen()
  const commonClasses = useCommonStyles()

  const now = useRef(getRoundedNowJS())

  const commonParams = {
    params: { pagination: { page: 1, perPage: 1 }, filter: { vehicle_id: record?.id, timeline_start: now.current } },
    options: { enabled: Boolean(record?.id) },
  }

  const { total: numOfBookings } = useGetList(
    bookingConfig.name,
    {
      ...commonParams.params,
      filter: { ...commonParams.params.filter, ...bookingConfig.options.defaultFilterValues },
    },
    commonParams.options,
  )

  const { total: numOfUnavailabilities } = useGetList(
    vehicleUnavailabilitiesConfig.name,
    commonParams.params,
    commonParams.options,
  )

  const validateOrganisationChange = (v) => {
    if (type === UPDATE && record.organisation_id !== v && (numOfBookings > 0 || numOfUnavailabilities > 0)) {
      return { message: 'mymove.validation.organisationChangeWithUpcomingBookingsOrUnavailabilities' }
    }
  }

  if (type === UPDATE) {
    record.picture_data = record.picture ? { src: record.picture } : null
    record.information_document_data = record.information_document
      ? {
          src: record.information_document,
          title: translate('resources.vehicles.fields.information_document_data'),
        }
      : null
    record.details_object = getFieldObjectFromCollection(record.details)
    record.gateway = pick(record.gateway, ['code', 'vehicle_id'])
  }

  return (
    <AdvancedSimpleForm
      defaultValues={{ started_on: now.current, price_factor: 1, ...filterValues }}
      toolbar={<BasicFormToolbar formType={type} />}
      type={type}
    >
      <FormSubTitle text="identification" isFirstChild />

      <ImageInput
        source="picture_data"
        className={classnames(commonClasses.commonInput, commonClasses.imageInput)}
        labelSingle={translate('mymove.dropOrSelectYourWebpFileHere')}
        helperText={false}
        accept="image/webp"
        validate={required()}
      >
        <ImageField source="src" title="title" />
      </ImageInput>

      <FormDivider />

      <SelectInput choices={vehicleTypeChoices} source="type" validate={required()} readOnly={type === UPDATE} />

      <FormDataConsumer>
        {({ formData }) =>
          isVehicleCar(formData) && (
            <SelectInput
              choices={vehicleBlueprintSetChoices}
              source="blueprint_set"
              validate={required()}
              readOnly={type === UPDATE}
            />
          )
        }
      </FormDataConsumer>

      <FormDivider />

      <TextInput source="brand" validate={required()} />

      <TextInput source="model" validate={required()} />

      <FormDataConsumer>
        {({ formData }) => isVehicleCar(formData) && <NumberInput source="year" validate={required()} min={2000} />}
      </FormDataConsumer>

      <TextInput source="designation" validate={required()} />

      <FormDataConsumer>
        {({ formData }) =>
          isVehicleCar(formData) && <TextInput source="identification_number" validate={validateVIN()} />
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {({ formData }) =>
          isVehicleCar(formData) && (
            <>
              <FormSubTitle text="characteristics" />
              <SelectInput choices={vehicleTransmissionTypeChoices} source="transmission_type" validate={required()} />
              <AdvancedSelectInput source="keyless" />
              <NumberInput source="number_of_seats" min={1} validate={validateNumberOfSeats} />
              <NumberInput
                source="volume"
                min={1}
                InputProps={{ endAdornment: <InputAdornment position="end">m³</InputAdornment> }}
                validate={validateVolume}
              />
              <NumberInput
                source="value"
                validate={validateValue}
                format={(v) => (v ? v / 100 : null)}
                parse={(v) => v * 100}
                step={10}
                min={0}
                InputProps={{ endAdornment: <InputAdornment position="end">{env.CURRENCY_SYMBOL}</InputAdornment> }}
              />
              <FormDivider />
              <SelectInput choices={vehicleEnergyTypeChoices} source="energy_type" validate={required()} />
            </>
          )
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {({ formData }) =>
          isVehicleElectricCar(formData) && (
            <>
              <NumberInput
                source="maximum_autonomy"
                min={0}
                InputProps={{ endAdornment: <InputAdornment position="end">km</InputAdornment> }}
                validate={validateMaximumAutonomy}
              />
              <VehicleSpecialFieldInput code={VEHICLE_SPECIAL_FIELD_CODE_CHARGING_INSTRUCTIONS} />
            </>
          )
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {({ formData }) =>
          isVehicleFuelCar(formData) && (
            <>
              <NumberInput
                source="tank_size"
                min={0}
                InputProps={{ endAdornment: <InputAdornment position="end">L</InputAdornment> }}
                validate={validateTankSize}
              />
              <NumberInput
                source="average_consumption"
                step={0.1}
                min={0}
                InputProps={{ endAdornment: <InputAdornment position="end">L/100km</InputAdornment> }}
                validate={validateAverageConsumption}
              />
              <VehicleSpecialFieldInput code={VEHICLE_SPECIAL_FIELD_CODE_FUELING_INFO} />
            </>
          )
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {({ formData }) =>
          isVehicleCar(formData) && (
            <>
              <FormDivider />
              <ImageInput
                accept=".pdf"
                source="information_document_data"
                labelSingle={translate('mymove.dropOrSelectYourPDFDocumentHere')}
                className={classnames(
                  isSmallScreen ? commonClasses.commonInput : commonClasses.doubleInput,
                  commonClasses.imageInput,
                )}
                helperText={false}
              >
                <ImageField source="src" title="title" />
              </ImageInput>
            </>
          )
        }
      </FormDataConsumer>

      <FormSubTitle text="parameters" />

      <OrganisationReferenceInput source="organisation_id" validate={[required(), validateOrganisationChange]} />

      <HubReferenceInput />

      <FormDivider />

      <AdvancedDateTimeInput source="started_on" validate={required()} />

      <FormDataConsumer>
        {({ formData }) => (
          <AdvancedDateTimeInput
            source="ended_on"
            minDate={formData.started_on ? parseDateAsDateTime(formData.started_on) : undefined}
            validate={(v) => validateEndDateAfterStartDate(formData.started_on, v)}
          />
        )}
      </FormDataConsumer>

      <FormDivider />

      <NumberInput source="price_factor" step={0.01} min={0} validate={validatePriceFactor} />

      <NumberInput
        source="security_deposit_amount"
        validate={validateSecurityDepositAmount}
        format={(v) => (v ? v / 100 : null)}
        parse={(v) => v * 100}
        step={10}
        min={0}
        InputProps={{ endAdornment: <InputAdornment position="end">{env.CURRENCY_SYMBOL}</InputAdornment> }}
      />

      <FormDataConsumer>
        {({ formData }) =>
          isVehicleCar(formData) && (
            <PricePerKmNumberInput organisationId={formData.organisation_id} source="price_per_km" type={type} />
          )
        }
      </FormDataConsumer>

      <FormDivider />

      <VehicleSpecialFieldInput code={VEHICLE_SPECIAL_FIELD_CODE_PARKING_SPOT} />

      <FormDataConsumer>
        {({ formData }) =>
          isVehicleElectricCar(formData) && (
            <NumberInput
              source="charging_buffer"
              validate={validateChargingBuffer}
              format={(v) => (v ? v / 60 : null)}
              parse={(v) => (v ? v * 60 : null)}
              min={0}
              max={24}
              helperText="resources.vehicles.forms.helperTexts.charging_buffer"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {translate('mymove.units.time.hours', get(formData, 'charging_buffer') / 60 || 1)}
                  </InputAdornment>
                ),
              }}
            />
          )
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {({ formData }) =>
          isVehicleFuelCar(formData) && <VehicleSpecialFieldInput code={VEHICLE_SPECIAL_FIELD_CODE_FUEL_CARD_PIN} />
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {({ formData }) =>
          Boolean(formData.type) &&
          !isVehicleCar(formData) && (
            <VehicleSpecialFieldInput code={VEHICLE_SPECIAL_FIELD_CODE_CHARGING_INSTRUCTIONS} />
          )
        }
      </FormDataConsumer>

      <FormSubTitle text="providerDetails" />

      <SelectInput
        choices={vehicleGatewayCodeChoices}
        source="gateway.code"
        validate={required()}
        readOnly={type === UPDATE}
      />

      <TextInput source="gateway.vehicle_id" readOnly={type === UPDATE && !!record.gateway?.vehicle_id} />
    </AdvancedSimpleForm>
  )
}

export default VehicleFormLayout
