import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
  EditButton,
  EmailField,
  ImageField,
  ReferenceOneField,
  RichTextField,
  SimpleShowLayout,
  TopToolbar,
  useGetIdentity,
  useGetOne,
  useRecordContext,
  useResourceContext,
} from 'react-admin'

import AdvancedBooleanField from '../../components/AdvancedBooleanField'
import AdvancedShow from '../../components/AdvancedShow'
import AdvancedTextField from '../../components/AdvancedTextField'
import BookingEndChecksField from '../../components/BookingEndChecksField'
import HubsMapField from '../../components/HubsMapField'
import NFCCardsField from '../../components/NFCCardsField'
import OrganisationEmailNotificationsField from '../../components/OrganisationEmailNotificationsField'
import OrganisationRegistrationLinkField from '../../components/OrganisationRegistrationLinkField'
import PressureGraphs from '../../components/PressureGraphs'
import PriceField from '../../components/PriceField'
import ShowCardHeader from '../../components/ShowCardHeader'
import { BOOKING_END_CHECK_TANK_FILLED_UP } from '../../config/bookingEndChecks'
import {
  OPS_USER_ROLE_LEASING_COMPANY,
  SYSTEM_PERMISSION_READ,
  SYSTEM_PERMISSION_UPDATE,
} from '../../config/permissions'
import { useResourcePermissions } from '../../domain/permissions'
import hubsConfig from '../../resources/hubs/config'
import { useSmallScreen } from '../../utils/theme'
import accountConfig from '../accounts/config'
import { useGetCurrentAccount } from '../accounts/hooks'
import { ListReference } from '../common/list'
import { ShowActions, ShowCard, ShowReferenceLinks, ShowSubSectionTitleCard, useShowStyles } from '../common/show'
import { useGetHubsListForOrganisation } from '../hubs/hooks'
import { HubsListLayout } from '../hubs/list'
import HubShowLayout from '../hubs/show'
import organisationDetailsConfig from '../organisationDetails/config'
import pricingStrategies from '../pricingStrategies'
import { PricingStrategiesListLayout } from '../pricingStrategies/list'

const useStyles = makeStyles({
  mapContainer: {
    marginLeft: (props) => (props.isSmallScreen ? 0 : 20),
    marginTop: (props) => (props.isSmallScreen ? 10 : 24),
  },
})

const OrganisationShowLayout = () => {
  const [hasReadForAccount] = useResourcePermissions(accountConfig.name, SYSTEM_PERMISSION_READ)
  const account = useGetCurrentAccount()
  const record = useRecordContext()
  const { data: organisationDetails } = useGetOne(organisationDetailsConfig.name, { id: record.id })
  const showClasses = useShowStyles()

  return (
    <>
      <ShowCardHeader />
      {(!hasReadForAccount || Boolean(account)) && organisationDetails && (
        <>
          <ShowSubSectionTitleCard text="identification" />

          <SimpleShowLayout className={showClasses.fieldContainer}>
            <ImageField
              source="picture"
              title="name"
              label={false}
              sx={{ '& img': { width: 'auto !important', maxHeight: '6rem !important', marginTop: '9px !important' } }}
            />
          </SimpleShowLayout>
          <SimpleShowLayout className={showClasses.fieldContainer} data-cy="infoShowLayout">
            <AdvancedTextField source="name" />
            <EmailField source="contact_email" emptyText="n/a" />
          </SimpleShowLayout>

          <ShowSubSectionTitleCard text="fleetUsage" />

          <SimpleShowLayout className={showClasses.fieldContainer} data-cy="fleetSettingsShowLayout">
            <AdvancedBooleanField source="address_required" trueIcon={null} falseIcon={null} />
            <AdvancedBooleanField source="requires_face_check" trueIcon={null} falseIcon={null} />
            <AdvancedBooleanField source="requires_parking_spot" trueIcon={null} falseIcon={null} />
            <AdvancedBooleanField source="requires_damage_report_prompt" trueIcon={null} falseIcon={null} />
          </SimpleShowLayout>
          <SimpleShowLayout className={showClasses.fieldContainer} data-cy="nfcShowLayout">
            <NFCCardsField source="nfc_cards.name" />
          </SimpleShowLayout>
          <SimpleShowLayout className={showClasses.fieldContainer}>
            <BookingEndChecksField />
          </SimpleShowLayout>

          {record.booking_end_checks?.includes(BOOKING_END_CHECK_TANK_FILLED_UP) && (
            <>
              <ShowSubSectionTitleCard text="fines" />
              <SimpleShowLayout className={showClasses.fieldContainer}>
                <AdvancedBooleanField
                  source="missing_fuel_fine"
                  label="resources.organisations.fields.missing_fuel_fine.name"
                  trueIcon={null}
                  falseIcon={null}
                />
                <PriceField source="missing_fuel_fine.operational_fees" />
                <PriceField source="missing_fuel_fine.fuel_fees" />
              </SimpleShowLayout>
            </>
          )}

          <ShowSubSectionTitleCard text="notifications" />
          <SimpleShowLayout className={showClasses.fieldContainer}>
            <OrganisationEmailNotificationsField />
          </SimpleShowLayout>

          <ShowSubSectionTitleCard text="legalContent" />
          <SimpleShowLayout className={showClasses.fieldContainer}>
            <Box label="resources.organisations.fields.rental_terms_and_conditions" maxHeight={200} overflow="scroll">
              <RichTextField source="rental_terms_and_conditions" emptyText="n/a" />
            </Box>
          </SimpleShowLayout>

          {account?.is_free_to_join && (
            <>
              <ShowSubSectionTitleCard text="registrationInTheApp" />
              <SimpleShowLayout className={showClasses.fieldContainer}>
                <OrganisationRegistrationLinkField />
              </SimpleShowLayout>
            </>
          )}
          <ShowActions allowedActions={organisationDetails.allowed_actions} />
          <ShowReferenceLinks />
        </>
      )}
    </>
  )
}

const OrganisationActions = () => {
  const resource = useResourceContext()
  const [hasEdit] = useResourcePermissions(resource, SYSTEM_PERMISSION_UPDATE)
  return hasEdit ? (
    <TopToolbar>
      <EditButton label="resources.organisations.forms.update.title" />
    </TopToolbar>
  ) : (
    <Box mt={2} />
  )
}

const OrganisationAside = () => {
  const record = useRecordContext()
  const { data: hubs, total: numberOfHubs } = useGetHubsListForOrganisation(record.id)
  const { identity } = useGetIdentity()
  const isSmallScreen = useSmallScreen()
  const showClasses = useShowStyles()
  const classes = useStyles({ isSmallScreen })

  return identity ? (
    <>
      {!!numberOfHubs && numberOfHubs === 1 && (
        <ReferenceOneField reference={hubsConfig.name} target="organisation_id" perPage={1}>
          <ShowCard>
            <HubShowLayout />
          </ShowCard>
        </ReferenceOneField>
      )}
      {!!numberOfHubs && numberOfHubs > 1 && (
        <div className={showClasses.row}>
          <div className={showClasses.expanded}>
            <ListReference reference={hubsConfig.name} target="organisation_id" sort={hubsConfig.options.defaultSort}>
              <HubsListLayout organisationId={record.id} />
            </ListReference>
          </div>
          <Box width="100%" maxWidth={isSmallScreen ? null : 480} className={classes.mapContainer}>
            <SimpleShowLayout
              className={showClasses.map}
              sx={{ '& .RaSimpleShowLayout-row': { margin: 0, width: '100%' } }}
            >
              <HubsMapField hubs={Object.values(hubs)} label={false} />
            </SimpleShowLayout>
          </Box>
        </div>
      )}
      <ListReference
        reference={pricingStrategies.config.name}
        target="organisation_id"
        sort={pricingStrategies.config.options.defaultSort}
      >
        <PricingStrategiesListLayout organisationId={record.id} />
      </ListReference>
      {identity.role !== OPS_USER_ROLE_LEASING_COMPANY && <PressureGraphs />}
    </>
  ) : null
}

export default () => (
  <AdvancedShow actions={<OrganisationActions />} aside={<OrganisationAside />}>
    <OrganisationShowLayout />
  </AdvancedShow>
)
