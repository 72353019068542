import { ArrayInput, required, SelectInput, SimpleFormIterator, TextInput, useResourceContext } from 'react-admin'
import { useWatch } from 'react-hook-form'

import { NFC_CARD_TYPES } from '../config/nfc'
import { SimpleFormIteratorAddButton } from '../resources/common/forms'

const nfcTypesChoices = Object.entries(NFC_CARD_TYPES).map(([k, v]) => ({ id: k, name: v }))

const NFCArrayInput = ({ source, maxAmount }) => {
  const nfcCards = useWatch({ name: 'nfc_cards' })

  const resource = useResourceContext()
  const fieldPrefix = `resources.${resource}.fields.${source}`
  return (
    <ArrayInput source={source} label={`${fieldPrefix}.name`} helperText={false}>
      <SimpleFormIterator
        disableReordering
        TransitionProps={{ enter: false, exit: false }}
        addButton={<SimpleFormIteratorAddButton />}
        disableAdd={nfcCards?.length >= maxAmount}
        inline
      >
        <SelectInput
          source="nfc_type"
          label={`${fieldPrefix}.nfc_type`}
          choices={nfcTypesChoices}
          validate={required()}
        />
        <TextInput source="card_id" label={`${fieldPrefix}.card_id`} validate={required()} />
      </SimpleFormIterator>
    </ArrayInput>
  )
}

export default NFCArrayInput
