import { useGetList, useGetOne } from 'react-admin'

import config from './config'
import { useAccounts } from '../../api/accountsProvider'
import { SYSTEM_PERMISSION_READ } from '../../config/permissions'
import { useResourcePermissions } from '../../domain/permissions'
import hubsConfig from '../hubs/config'
import { useGetOrganisations } from '../organisations/hooks'

export const useGetCurrentAccount = () => {
  const { currentAccount } = useAccounts()
  const [hasRead] = useResourcePermissions(config.name, SYSTEM_PERMISSION_READ)
  const { data: account } = useGetOne(
    config.name,
    { id: currentAccount?.id },
    { enabled: hasRead && Boolean(currentAccount?.id) },
  )
  return account
}

export const useCurrentAccountSelectors = () => {
  const [hasReadForHubs] = useResourcePermissions(hubsConfig.name, SYSTEM_PERMISSION_READ)

  const pagination = { page: 1, perPage: 1 }
  const organisations = useGetOrganisations({ pagination })
  const hubs = useGetList(
    hubsConfig.name,
    { pagination, sort: hubsConfig.options.defaultSort },
    { enabled: hasReadForHubs },
  )

  const hasSingleOrganisation = organisations.total
    ? organisations.total === 1
    : organisations.total === 0
    ? false
    : undefined
  const singleOrganisationId = hasSingleOrganisation ? organisations.data[0].id : null
  const hasSingleHub = hubs.total ? hubs.total === 1 : hubs.total === 0 ? false : undefined

  return { hasSingleOrganisation, singleOrganisationId, hasSingleHub }
}
