import Icon from '@mui/icons-material/ListAlt'

import PeriodField from '../../components/PeriodField'
import ReferenceValue from '../../components/ReferenceValue'
import { BOOKING_ACTIONS } from '../../config/bookings'
import { SYSTEM_PERMISSION_READ } from '../../config/permissions'
import { useCommonStyles } from '../../config/theme'
import { useResourcePermissions } from '../../domain/permissions'
import usersConfig from '../users/config'
import vehiclesConfig from '../vehicles/config'

export default {
  name: 'bookings',
  icon: Icon,
  recordRepresentation: () => {
    const BookingRecordRepresentation = () => {
      const commonClasses = useCommonStyles()
      const [hasReadForUsers] = useResourcePermissions(usersConfig.name, SYSTEM_PERMISSION_READ)
      const referenceConfig = hasReadForUsers ? usersConfig : vehiclesConfig
      return (
        <>
          <ReferenceValue reference={referenceConfig.name} emptyText={null} />
          {'\u00a0•\u00a0'}
          <PeriodField
            addTime
            className={commonClasses.inheritedFont}
            endedOnSource="end_scheduled_on"
            startedOnSource="start_scheduled_on"
          />
        </>
      )
    }
    return <BookingRecordRepresentation />
  },
  options: {
    referenceKey: 'booking_id',
    defaultSort: { field: 'effective_started_on', order: 'DESC' },
    defaultFilterValues: { cancelled: false },
    references: ['organisations', 'users', 'vehicles'],
    actions: BOOKING_ACTIONS,
    badge: true,
  },
}
