import { createContext, useContext, useEffect, useState } from 'react'
import { useResourceDefinitions, useStoreContext } from 'react-admin'

const AccountsContext = createContext()

export const AccountsProvider = (props) => <AccountsContext.Provider {...props} />

export const useAccounts = () => {
  const accountsProvider = useContext(AccountsContext)
  const [currentAccount, setCurrentAccount] = useState(null)
  const store = useStoreContext()
  const resourcesDefinitions = useResourceDefinitions()
  const resources = Object.keys(resourcesDefinitions)

  useEffect(() => {
    ;(async () => setCurrentAccount(await accountsProvider.getCurrentAccount()))()
  }, [accountsProvider])
  return {
    accounts: accountsProvider.getAccounts(),
    currentAccount,
    setCurrentAccount: (account) => {
      accountsProvider.setCurrentAccount(account)
      accountsProvider.navigateToCurrentAccount()
      store.removeItem('timeline')
      resources.forEach((resource) => {
        const key = `${resource}.listParams`
        store.removeItem(key)
      })
    },
  }
}

export default (baseUrl, apiClient) => {
  const currentAccountIdKey = 'currentAccountId'
  let currentAccountId = null
  let cache = null

  const clear = () => {
    window.localStorage.removeItem(currentAccountIdKey)
    currentAccountId = null
    cache = null
  }

  const fetchAccounts = async () => {
    try {
      const { json } = await apiClient(`${baseUrl}/me`)
      cache = json.accounts?.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
      return cache
    } catch (error) {
      return null
    }
  }

  const getAccounts = () => cache ?? []

  const setCurrentAccount = (account) => {
    const { id } = account
    currentAccountId = id
    window.localStorage.setItem(currentAccountIdKey, id)
  }

  const navigateToCurrentAccount = () =>
    getCurrentAccount().then((currentAccount) => window.location.assign(`/${currentAccount.slug}`))

  const getCurrentAccount = async () => {
    const accounts = cache ?? (await fetchAccounts())
    if (!currentAccountId) {
      currentAccountId = window.localStorage.getItem(currentAccountIdKey)
    }
    const currentAccount = accounts?.find((account) => account.id === currentAccountId)
    if (currentAccount) {
      return currentAccount
    }
    return accounts?.[0]
  }

  const findAccountBySlug = (slug) => {
    const accounts = getAccounts()
    return accounts?.find((account) => account.slug === slug)
  }

  const client = async (url, options) => {
    const currentAccount = await getCurrentAccount()
    return apiClient(url, {
      ...options,
      headers: new Headers({
        ...Object.fromEntries(options?.headers ?? []),
        'x-account-id': currentAccount?.id ?? 'none',
      }),
    })
  }

  return {
    clear,
    getAccounts,
    setCurrentAccount,
    getCurrentAccount,
    findAccountBySlug,
    navigateToCurrentAccount,
    client,
  }
}
