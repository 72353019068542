import { PresetManager } from 'bryntum-scheduler'
import { DateTime } from 'luxon'
import { localStorageStore } from 'react-admin'

const store = localStorageStore()

const weekTranslation = { en: 'Week', fr: 'Semaine', nl: 'Week' }

const DEFAULT_PRESETS = {
  weekAndMonth: {
    headers: [
      { unit: 'month', dateFormat: 'MMM YYYY' },
      { unit: 'week', dateFormat: 'DD/MM' },
    ],
    displayDateFormat: 'll LT',
  },
  weekAndDayLetter: {
    headers: [
      {
        unit: 'week',
        renderer: (start) => {
          const weekNumber = DateTime.fromISO(start.toISOString()).toFormat('WW')
          const dayMonth = DateTime.fromISO(start.toISOString()).toFormat('dd/MM')
          return `${weekTranslation[store.getItem('locale') ?? 'en']} ${weekNumber} (${dayMonth})`
        },
      },
      { unit: 'day', dateFormat: 'dd DD/MM' },
    ],
    displayDateFormat: 'll LT',
    timeResolution: {
      unit: 'minute',
      increment: 15,
    },
  },
  hourAndDay: {
    headers: [
      { unit: 'day', dateFormat: 'ddd DD/MM' },
      { unit: 'hour', dateFormat: 'LT' },
    ],
    displayDateFormat: 'll LT',
    timeResolution: {
      unit: 'minute',
      increment: 5,
    },
  },
}

PresetManager.records
  .filter((r) => Object.keys(DEFAULT_PRESETS).includes(r.id))
  .forEach((preset) => {
    const customPreset = {
      id: preset.id + 'Custom',
      base: preset.id,
      ...DEFAULT_PRESETS[preset.id],
    }
    PresetManager.add(customPreset)
  })

export const weekAndMonthCustomName = 'weekAndMonthCustom'
export const weekAndDayLetterCustomName = 'weekAndDayLetterCustom'
export const hourAndDayCustomName = 'hourAndDayCustom'

export const defaultPresets = PresetManager.records.filter((r) => r.id.indexOf('Custom') > -1)
