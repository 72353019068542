import { MarkerClusterer } from '@googlemaps/markerclusterer'
import { Box, Typography } from '@mui/material'
import { Circle, InfoWindow, Map as GMap, Polyline } from 'google-maps-react'
import { isEmpty } from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useStore } from 'react-admin'

import mapStyles from './styles.json'
import { lightTheme } from '../../config/theme'

const MAP_DEFAULT_ZOOM = 15

const GoogleMapContainer = ({ google, circles = [], markers = [], polylines = [] }) => {
  const [selectedMarker, setSelectedMarker] = useState(null)
  const [shouldDisplayPolylines, setShouldDisplayPolylines] = useState(false)
  const [mapTypeId, setMapTypeId] = useStore('google-maps-type', 'roadmap')

  const mapRef = useRef(null)
  const markersClusterRef = useRef(null)

  const onMarkerClick = (marker) => setSelectedMarker({ info: marker.markerData.info, marker })

  const fitBoundsToMarkers = useCallback(
    (map, markers) => {
      const bounds = new google.maps.LatLngBounds()
      markers.forEach((marker) => {
        const { lat, lng } = marker.position
        bounds.extend(new google.maps.LatLng(lat, lng))
      })
      map.fitBounds(bounds)

      // We prevent the map to zoom too much at the beginning
      const zoomChangeBoundsListener = google.maps.event.addListenerOnce(map, 'bounds_changed', function () {
        if (this.getZoom() > MAP_DEFAULT_ZOOM) this.setZoom(MAP_DEFAULT_ZOOM)
      })
      setTimeout(() => {
        google.maps.event.removeListener(zoomChangeBoundsListener)
      }, 2000)
    },
    [JSON.stringify(google)], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getGoogleMarkersForClusterer = useCallback(
    (markers) => {
      const googleMarkers = markers.map((markerData) => {
        const googleMarker = new google.maps.Marker({
          position: markerData.position,
          icon: markerData.icon,
          map: mapRef.current,
          markerData,
        })
        google.maps.event.addListener(googleMarker, 'click', () => onMarkerClick(googleMarker))
        return googleMarker
      })
      return googleMarkers
    },
    [JSON.stringify(google)], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const onMapReady = useCallback(
    (map, shouldFitBounds) => {
      mapRef.current = map

      // Set map type ID (roadmap, satellite, hybrid, terrain) based on user preference
      map.setMapTypeId(mapTypeId)

      // Use custom map styles
      map.setOptions({ styles: mapStyles })

      // Center map on existing markers if applicable
      if (shouldFitBounds) {
        fitBoundsToMarkers(map, markers)
      }

      // Add zoom change listener
      google.maps.event.addListener(map, 'zoom_changed', () => {
        const currentZoom = map.getZoom()
        setSelectedMarker(null) // this fixes a bug where the map centers on the open info window when zooming out and clicking on a cluster
        setShouldDisplayPolylines(currentZoom >= MAP_DEFAULT_ZOOM)
      })

      // Initialize MarkerClusterer with available markers & event listeners
      const googleMarkers = getGoogleMarkersForClusterer(markers)
      markersClusterRef.current = new MarkerClusterer({
        map: mapRef.current,
        markers: googleMarkers,
        onClusterClick: (event, cluster, map) => {
          setSelectedMarker(null)
          map.fitBounds(cluster.bounds, 0)
        },
        renderer: {
          render: (cluster, stats) => {
            const bgColor =
              cluster.count > Math.max(10, stats.clusters.markers.mean)
                ? lightTheme.palette.error.main
                : lightTheme.palette.primary[500]
            const svg = `
              <svg fill="${bgColor}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
                <circle cx="120" cy="120" opacity=".8" r="70" />
                <circle cx="120" cy="120" opacity=".5" r="90" />
                <circle cx="120" cy="120" opacity=".2" r="110" />
              </svg>
            `
            return new google.maps.Marker({
              icon: {
                url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svg)}`,
                scaledSize: new google.maps.Size(50, 50),
                anchor: new window.google.maps.Point(25, 25), // adjust anchor based on the size
              },
              label: {
                text: String(cluster.count),
                color: 'white',
                fontSize: '10px',
                fontWeight: '600',
              },
              position: cluster.position,
              zIndex: 1000 + cluster.count,
            })
          },
        },
      })

      // Display polylines if zoom level is high enough
      setShouldDisplayPolylines(map.getZoom() >= MAP_DEFAULT_ZOOM)
    },
    [JSON.stringify(google), JSON.stringify(markers)], // eslint-disable-line react-hooks/exhaustive-deps
  )

  useEffect(() => {
    if (mapRef.current && !isEmpty(markers)) {
      if (markerPositionsInfo.hasSeveralMarkerPositions) {
        fitBoundsToMarkers(mapRef.current, markers)
      }
      if (markersClusterRef.current) {
        // Clear existing markers from the clusterer
        markersClusterRef.current.clearMarkers()
        // Add markers to the clusterer with event listeners
        const googleMarkers = getGoogleMarkersForClusterer(markers)
        markersClusterRef.current.addMarkers(googleMarkers)
      }
    }
  }, [JSON.stringify(google), JSON.stringify(markers)]) // eslint-disable-line react-hooks/exhaustive-deps

  const onMapClick = useCallback(() => {
    if (selectedMarker) {
      setSelectedMarker(null)
    }
  }, [selectedMarker])

  const markerPositionsInfo = useMemo(() => {
    const distinctMarkerLatitudes = [...new Set(markers.map((marker) => marker.position?.lat))].filter(
      (lat) => lat !== undefined,
    )
    const distinctMarkerLongitudes = [...new Set(markers.map((marker) => marker.position?.lng))].filter(
      (lng) => lng !== undefined,
    )
    const hasOneMarkerPosition = distinctMarkerLatitudes.length === 1 && distinctMarkerLongitudes.length === 1
    const hasSeveralMarkerPositions = distinctMarkerLatitudes.length > 1 || distinctMarkerLongitudes.length > 1
    return { hasOneMarkerPosition, hasSeveralMarkerPositions }
  }, [JSON.stringify(markers)]) // eslint-disable-line react-hooks/exhaustive-deps

  const infoWindowTypographyCommonProps = { gutterBottom: true, sx: { '&::selection': { backgroundColor: '#B3D7FE' } } }

  return (
    <GMap
      google={google}
      zoom={MAP_DEFAULT_ZOOM}
      style={{ borderRadius: '5px' }}
      initialCenter={markerPositionsInfo.hasOneMarkerPosition ? markers[0].position : undefined}
      onMaptypeidChanged={() => setMapTypeId(mapRef.current.get('mapTypeId'))}
      onReady={(_, map) => onMapReady(map, markerPositionsInfo.hasSeveralMarkerPositions)}
      onClick={onMapClick}
    >
      {circles.map((circle, index) => (
        <Circle
          key={index}
          radius={circle.radius}
          center={circle.center}
          strokeColor="transparent"
          fillColor={lightTheme.palette.primary[500]}
          fillOpacity={0.3}
        />
      ))}

      {shouldDisplayPolylines && polylines.map((path, index) => <Polyline key={index} path={path} />)}

      <InfoWindow
        marker={selectedMarker?.marker}
        visible={Boolean(selectedMarker?.info)}
        onClose={() => setSelectedMarker(null)}
      >
        <Box sx={{ color: 'black' }}>
          {selectedMarker?.info?.name && (
            <Typography variant="subtitle2" {...infoWindowTypographyCommonProps}>
              {selectedMarker.info.name}
            </Typography>
          )}
          {selectedMarker?.info?.description && (
            <Typography variant="body2" {...infoWindowTypographyCommonProps}>
              {selectedMarker.info.description}
            </Typography>
          )}
        </Box>
      </InfoWindow>
    </GMap>
  )
}

const GoogleMap = (props) => <GoogleMapContainer {...props} google={window.google} />

export default GoogleMap
