export const NFC_CARD_TYPE_AWID = 'AWID'
export const NFC_CARD_TYPE_EM_410X = 'EM_410X'
export const NFC_CARD_TYPE_HID_ICLASS = 'HID_ICLASS'
export const NFC_CARD_TYPE_HID_PROX = 'HID_PROX'
export const NFC_CARD_TYPE_HITAG_1 = 'HITAG_1'
export const NFC_CARD_TYPE_ISO14443A_4 = 'ISO14443A_4'
export const NFC_CARD_TYPE_ISO14443A_MIFARE = 'ISO14443A_MIFARE'
export const NFC_CARD_TYPE_ISO14443B_4 = 'ISO14443B_4'
export const NFC_CARD_TYPE_ISO14443B_PRIME = 'ISO14443B_PRIME'
export const NFC_CARD_TYPE_ISO15693 = 'ISO15693'
export const NFC_CARD_TYPE_LEGIC = 'LEGIC'

export const NFC_CARD_TYPES = {
  [NFC_CARD_TYPE_AWID]: 'AWID',
  [NFC_CARD_TYPE_EM_410X]: 'EM410x',
  [NFC_CARD_TYPE_HID_ICLASS]: 'HID iCLASS',
  [NFC_CARD_TYPE_HID_PROX]: 'HID Proximity',
  [NFC_CARD_TYPE_HITAG_1]: 'HITAG 1',
  [NFC_CARD_TYPE_ISO14443A_4]: 'ISO14443A-4',
  [NFC_CARD_TYPE_ISO14443A_MIFARE]: 'ISO14443A-Mifare',
  [NFC_CARD_TYPE_ISO14443B_4]: 'ISO14443B-4',
  [NFC_CARD_TYPE_ISO14443B_PRIME]: 'ISO14443B-Prime',
  [NFC_CARD_TYPE_ISO15693]: 'ISO15693',
  [NFC_CARD_TYPE_LEGIC]: 'LEGIC',
}
