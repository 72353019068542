import { Scheduler } from 'bryntum-scheduler'
import React from 'react'

import { createWidget, shouldComponentUpdate, processWidgetContent } from './WrapperHelper.jsx'

class BryntumScheduler extends React.Component {
  constructor() {
    super(...arguments)
    this.processWidgetContent = processWidgetContent
    this.portalContainerClass = 'b-react-portal-container'
    this.state = {
      portals: new Map(),
      generation: 0,
    }
    this.projectStores = true
    this.dataStores = {
      assignmentStore: 'assignments',
      dependencyStore: 'dependencies',
      eventStore: 'events',
      resourceStore: 'resources',
      resourceTimeRangeStore: 'resourceTimeRanges',
      timeRangeStore: 'timeRanges',
    }
  }

  componentDidMount() {
    this.instance = createWidget(this)
  }

  componentWillUnmount() {
    var _a, _b
    ;(_b = (_a = this.instance) === null || _a === void 0 ? void 0 : _a.destroy) === null || _b === void 0
      ? void 0
      : _b.call(_a)
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shouldComponentUpdate(this, nextProps, nextState)
  }

  render() {
    const className = `b-react-scheduler-container`
    return React.createElement(
      React.Fragment,
      null,
      React.createElement(
        'div',
        { className: className, ref: (element) => (this.element = element) },
        Array.from(this.state.portals).map((item) => item[1]),
      ),
      React.createElement('div', {
        ref: (el) => (this.portalsCache = el),
        className: 'b-react-portals-cache',
        style: { display: 'none' },
      }),
    )
  }
}

BryntumScheduler.instanceClass = Scheduler
BryntumScheduler.instanceName = 'Scheduler'
BryntumScheduler.isView = true

BryntumScheduler.featureNames = [
  'cellCopyPasteFeature',
  'cellEditFeature',
  'cellMenuFeature',
  'cellTooltipFeature',
  'columnAutoWidthFeature',
  'columnDragToolbarFeature',
  'columnLinesFeature',
  'columnPickerFeature',
  'columnRenameFeature',
  'columnReorderFeature',
  'columnResizeFeature',
  'dependenciesFeature',
  'dependencyEditFeature',
  'dependencyMenuFeature',
  'eventCopyPasteFeature',
  'eventDragFeature',
  'eventDragCreateFeature',
  'eventDragSelectFeature',
  'eventEditFeature',
  'eventFilterFeature',
  'eventMenuFeature',
  'eventNonWorkingTimeFeature',
  'eventResizeFeature',
  'eventTooltipFeature',
  'excelExporterFeature',
  'fileDropFeature',
  'fillHandleFeature',
  'filterFeature',
  'filterBarFeature',
  'groupFeature',
  'groupSummaryFeature',
  'headerMenuFeature',
  'headerZoomFeature',
  'labelsFeature',
  'lockRowsFeature',
  'mergeCellsFeature',
  'nonWorkingTimeFeature',
  'panFeature',
  'pdfExportFeature',
  'printFeature',
  'quickFindFeature',
  'regionResizeFeature',
  'resourceMenuFeature',
  'resourceTimeRangesFeature',
  'rowCopyPasteFeature',
  'rowExpanderFeature',
  'rowReorderFeature',
  'rowResizeFeature',
  'scheduleContextFeature',
  'scheduleMenuFeature',
  'scheduleTooltipFeature',
  'scrollButtonsFeature',
  'searchFeature',
  'simpleEventEditFeature',
  'sortFeature',
  'splitFeature',
  'stickyCellsFeature',
  'stickyEventsFeature',
  'stripeFeature',
  'summaryFeature',
  'timeAxisHeaderMenuFeature',
  'timeRangesFeature',
  'timeSelectionFeature',
  'treeFeature',
  'treeGroupFeature',
]

BryntumScheduler.configNames = [
  'adopt',
  'allowCreate',
  'allowCreateOnlyParent',
  'animateRemovingRows',
  'animateTreeNodeToggle',
  'ariaDescription',
  'ariaLabel',
  'autoAdjustTimeAxis',
  'autoHeight',
  'bbar',
  'bodyCls',
  'bubbleEvents',
  'bufferCoef',
  'bufferThreshold',
  'callOnFunctions',
  'collapsible',
  'config',
  'contentElementCls',
  'contextMenuTriggerEvent',
  'createEventOnDblClick',
  'creationTooltip',
  'crudManagerClass',
  'date',
  'defaultRegion',
  'defaultResourceImageName',
  'descriptionRenderer',
  'deselectAllOnScheduleClick',
  'deselectOnClick',
  'destroyStore',
  'destroyStores',
  'disableGridColumnIdWarning',
  'disableGridRowModelWarning',
  'dock',
  'durationDisplayPrecision',
  'enableDeleteKey',
  'enableRecurringEvents',
  'enableSticky',
  'enableTextSelection',
  'enableTransactionalFeatures',
  'endParamName',
  'eventBarTextField',
  'eventRenderer',
  'eventRendererThisObj',
  'eventSelectionDisabled',
  'fillLastColumn',
  'fixedRowHeight',
  'footer',
  'fullRowRefresh',
  'getDateConstraints',
  'getRowHeight',
  'header',
  'hideHorizontalScrollbar',
  'highlightPredecessors',
  'highlightSuccessors',
  'hoverCls',
  'ignoreDomEventsWhileScrolling',
  'ignoreParentReadOnly',
  'infiniteScroll',
  'listeners',
  'loadMask',
  'loadMaskDefaults',
  'loadMaskError',
  'localizable',
  'maintainSelectionOnDatasetChange',
  'managedEventSizing',
  'maskDefaults',
  'masked',
  'maxDate',
  'maxTimeAxisUnit',
  'minDate',
  'mode',
  'monitorResize',
  'multiEventSelect',
  'narrowEventWidth',
  'owner',
  'partner',
  'passStartEndParameters',
  'plugins',
  'preCalculateHeightLimit',
  'preserveFocusOnDatasetChange',
  'preserveScrollOnDatasetChange',
  'preventTooltipOnTouch',
  'range',
  'relayStoreEvents',
  'resizeToFitIncludesHeader',
  'resourceColumns',
  'resourceImageExtension',
  'resourceImagePath',
  'resourceTimeRangeRenderer',
  'responsiveLevels',
  'ripple',
  'rootElement',
  'scrollerClass',
  'scrollExtensionThreshold',
  'scrollManager',
  'showCreationTooltip',
  'showDirty',
  'showEventColorPickers',
  'showLagInTooltip',
  'showRecurringUI',
  'snapRelativeToEventStartDate',
  'startParamName',
  'stateful',
  'statefulEvents',
  'stateId',
  'stateProvider',
  'stepUnit',
  'stickyHeaders',
  'strips',
  'subGridConfigs',
  'suppressFit',
  'syncMask',
  'tab',
  'tbar',
  'terminalCls',
  'terminalSides',
  'timeAxis',
  'triggerSelectionChangeOnRemove',
  'type',
  'ui',
  'verticalTimeAxisColumn',
  'visibleZoomFactor',
  'weekStartDay',
  'weight',
  'zoomKeepsOriginalTimespan',
  'zoomOnMouseWheel',
  'zoomOnTimeAxisDoubleClick',
]

BryntumScheduler.propertyConfigNames = [
  'alignSelf',
  'allowDropOnEventBar',
  'allowOverlap',
  'appendTo',
  'ariaLive',
  'assignments',
  'assignmentStore',
  'barMargin',
  'cls',
  'collapsed',
  'columnLines',
  'columns',
  'creationTooltipTemplate',
  'crudManager',
  'data',
  'dataset',
  'dateFormat',
  'dateSeparator',
  'dependencies',
  'dependencyStore',
  'descriptionFormat',
  'disabled',
  'displayDateFormat',
  'emptyText',
  'enableEventAnimations',
  'enableUndoRedoKeys',
  'endDate',
  'eventColor',
  'eventLayout',
  'events',
  'eventStore',
  'eventStyle',
  'extraData',
  'fillTicks',
  'flex',
  'forceFit',
  'height',
  'hidden',
  'hideFooters',
  'hideHeaders',
  'hideRowHover',
  'id',
  'insertBefore',
  'insertFirst',
  'isEventSelectable',
  'keyMap',
  'longPressTime',
  'margin',
  'maxHeight',
  'maxWidth',
  'maxZoomLevel',
  'milestoneAlign',
  'milestoneCharWidth',
  'milestoneLayoutMode',
  'milestoneTextPosition',
  'minHeight',
  'minWidth',
  'minZoomLevel',
  'onAfterDependencyCreateDrop',
  'onAfterDependencySave',
  'onAfterDragCreate',
  'onAfterEventDrop',
  'onAfterEventEdit',
  'onAfterEventSave',
  'onAssignmentSelectionChange',
  'onBeforeAssignmentDelete',
  'onBeforeAssignmentSelectionChange',
  'onBeforeCancelCellEdit',
  'onBeforeCellDelete',
  'onBeforeCellEditStart',
  'onBeforeColumnDragStart',
  'onBeforeColumnDropFinalize',
  'onBeforeColumnResize',
  'onBeforeCopy',
  'onBeforeCSVExport',
  'onBeforeDependencyAdd',
  'onBeforeDependencyCreateDrag',
  'onBeforeDependencyCreateFinalize',
  'onBeforeDependencyDelete',
  'onBeforeDependencyEdit',
  'onBeforeDependencyEditShow',
  'onBeforeDependencySave',
  'onBeforeDestroy',
  'onBeforeDragCreate',
  'onBeforeDragCreateFinalize',
  'onBeforeEventAdd',
  'onBeforeEventDelete',
  'onBeforeEventDrag',
  'onBeforeEventDragSelect',
  'onBeforeEventDropFinalize',
  'onBeforeEventEdit',
  'onBeforeEventEditShow',
  'onBeforeEventResize',
  'onBeforeEventResizeFinalize',
  'onBeforeEventSave',
  'onBeforeEventSelectionChange',
  'onBeforeExcelExport',
  'onBeforeFillHandleDragStart',
  'onBeforeFinishCellEdit',
  'onBeforeHide',
  'onBeforePan',
  'onBeforePaste',
  'onBeforePdfExport',
  'onBeforePresetChange',
  'onBeforeRenderRow',
  'onBeforeRenderRows',
  'onBeforeRowCollapse',
  'onBeforeRowExpand',
  'onBeforeSelectionChange',
  'onBeforeSetRecord',
  'onBeforeShow',
  'onBeforeShowTerminals',
  'onBeforeStateApply',
  'onBeforeStateSave',
  'onBeforeToggleGroup',
  'onBeforeToggleNode',
  'onCancelCellEdit',
  'onCatchAll',
  'onCellClick',
  'onCellContextMenu',
  'onCellDblClick',
  'onCellMenuBeforeShow',
  'onCellMenuItem',
  'onCellMenuShow',
  'onCellMenuToggleItem',
  'onCellMouseEnter',
  'onCellMouseLeave',
  'onCellMouseOut',
  'onCellMouseOver',
  'onCollapse',
  'onCollapseNode',
  'onColumnDrag',
  'onColumnDragStart',
  'onColumnDrop',
  'onColumnResize',
  'onColumnResizeStart',
  'onContextMenuItem',
  'onContextMenuToggleItem',
  'onCopy',
  'onCurrentTimelineUpdate',
  'onDataChange',
  'onDateRangeChange',
  'onDependenciesDrawn',
  'onDependencyClick',
  'onDependencyContextMenu',
  'onDependencyCreateDragStart',
  'onDependencyCreateDrop',
  'onDependencyDblClick',
  'onDependencyMenuBeforeShow',
  'onDependencyMenuItem',
  'onDependencyMenuShow',
  'onDependencyMouseOut',
  'onDependencyMouseOver',
  'onDependencyValidationComplete',
  'onDependencyValidationStart',
  'onDestroy',
  'onDirtyStateChange',
  'onDragCreateEnd',
  'onDragCreateStart',
  'onDragSelecting',
  'onEventAutoCreated',
  'onEventClick',
  'onEventContextMenu',
  'onEventDblClick',
  'onEventDrag',
  'onEventDragAbort',
  'onEventDragModeChange',
  'onEventDragReset',
  'onEventDragStart',
  'onEventDrop',
  'onEventEditBeforeSetRecord',
  'onEventKeyDown',
  'onEventKeyUp',
  'onEventMenuBeforeShow',
  'onEventMenuItem',
  'onEventMenuShow',
  'onEventMouseDown',
  'onEventMouseEnter',
  'onEventMouseLeave',
  'onEventMouseOut',
  'onEventMouseOver',
  'onEventMouseUp',
  'onEventPartialResize',
  'onEventResizeEnd',
  'onEventResizeStart',
  'onEventSelectionChange',
  'onExpand',
  'onExpandNode',
  'onFileDrop',
  'onFillHandleBeforeDragFinalize',
  'onFillHandleDrag',
  'onFillHandleDragAbort',
  'onFillHandleDragEnd',
  'onFillHandleDragStart',
  'onFinishCellEdit',
  'onFocusIn',
  'onFocusOut',
  'onGridRowBeforeDragStart',
  'onGridRowBeforeDropFinalize',
  'onGridRowDrag',
  'onGridRowDragAbort',
  'onGridRowDragStart',
  'onGridRowDrop',
  'onHeaderClick',
  'onHeaderMenuBeforeShow',
  'onHeaderMenuItem',
  'onHeaderMenuShow',
  'onHeaderMenuToggleItem',
  'onHide',
  'onLockRows',
  'onMouseOut',
  'onMouseOver',
  'onNavigate',
  'onPaint',
  'onPaste',
  'onPasteNotAllowed',
  'onPdfExport',
  'onPresetChange',
  'onReadOnly',
  'onRecompose',
  'onReleaseEvent',
  'onRenderEvent',
  'onRenderRow',
  'onRenderRows',
  'onResize',
  'onResourceHeaderClick',
  'onResourceHeaderContextmenu',
  'onResourceHeaderDblclick',
  'onResourceMenuBeforeShow',
  'onResourceMenuItem',
  'onResourceMenuShow',
  'onResourceMenuToggleItem',
  'onResourceTimeRangeClick',
  'onResourceTimeRangeContextMenu',
  'onResourceTimeRangeDblClick',
  'onResourceTimeRangeMouseDown',
  'onResourceTimeRangeMouseOut',
  'onResourceTimeRangeMouseOver',
  'onResourceTimeRangeMouseUp',
  'onResponsive',
  'onRowCollapse',
  'onRowExpand',
  'onRowMouseEnter',
  'onRowMouseLeave',
  'onScheduleClick',
  'onScheduleContextMenu',
  'onScheduleDblClick',
  'onScheduleMenuBeforeShow',
  'onScheduleMenuItem',
  'onScheduleMenuShow',
  'onScheduleMouseDown',
  'onScheduleMouseEnter',
  'onScheduleMouseLeave',
  'onScheduleMouseMove',
  'onScheduleMouseUp',
  'onScroll',
  'onScrollButtonClick',
  'onSelectionChange',
  'onSelectionModeChange',
  'onShow',
  'onSplit',
  'onSplitterCollapseClick',
  'onSplitterDragEnd',
  'onSplitterDragStart',
  'onSplitterExpandClick',
  'onStartCellEdit',
  'onSubGridCollapse',
  'onSubGridExpand',
  'onTickSizeChange',
  'onTimeAxisChange',
  'onTimeAxisHeaderClick',
  'onTimeAxisHeaderContextMenu',
  'onTimeAxisHeaderDblClick',
  'onTimeAxisHeaderMenuBeforeShow',
  'onTimeAxisHeaderMenuItem',
  'onTimeAxisHeaderMenuShow',
  'onTimelineContextChange',
  'onTimelineViewportResize',
  'onTimeRangeHeaderClick',
  'onTimeRangeHeaderContextMenu',
  'onTimeRangeHeaderDblClick',
  'onTimeSelectionChange',
  'onTimeSelectionElementClick',
  'onToggleGroup',
  'onToggleNode',
  'onToolClick',
  'onUnlockRows',
  'onUnsplit',
  'onVisibleDateRangeChange',
  'overlappingEventSorter',
  'preserveScroll',
  'presets',
  'project',
  'readOnly',
  'recurrenceConfirmationPopup',
  'resourceMargin',
  'resources',
  'resourceStore',
  'resourceTimeRanges',
  'resourceTimeRangeStore',
  'rowHeight',
  'rowLines',
  'scrollable',
  'selectionMode',
  'selectResourceOnEventNavigate',
  'selectResourceOnScheduleClick',
  'showTooltip',
  'snap',
  'startDate',
  'store',
  'terminalHideDelay',
  'terminalOffset',
  'terminalShowDelay',
  'terminalSize',
  'tickSize',
  'timeRanges',
  'timeRangeStore',
  'timeZone',
  'title',
  'tools',
  'tooltipTemplate',
  'transitionDuration',
  'updateTimelineContextOnScroll',
  'useContextualRecurrenceRules',
  'useInitialAnimation',
  'viewPreset',
  'visibleDate',
  'width',
  'workingTime',
]

BryntumScheduler.propertyNames = [
  'accessibility',
  'hasChanges',
  'isEngineReady',
  'originalStore',
  'parent',
  'scrollLeft',
  'scrollTop',
  'scrollX',
  'selectedAssignments',
  'selectedCell',
  'selectedCells',
  'selectedEvents',
  'selectedRecord',
  'selectedRecords',
  'selectedRows',
  'state',
  'timeResolution',
  'tooltip',
  'zoomLevel',
]

export default BryntumScheduler
