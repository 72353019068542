import { useTranslate } from 'ra-core'
import { useNotify, useRedirect, useRefresh, useResourceContext, useResourceDefinitions } from 'react-admin'
import { useNavigate } from 'react-router-dom'

import { useApi } from '../../api/apiProvider'
import { ERROR_MESSAGES, getErrorDataFromError } from '../../config/errors'

export const useHandleRedirection = () => {
  const navigate = useNavigate()
  const redirect = useRedirect()
  const resource = useResourceContext()
  const resources = useResourceDefinitions()

  const handleRedirection = async (record) => {
    if (record?.id && resources[resource].hasShow) {
      await redirect('show', resource, record.id)
    } else if (resources[resource].hasList) {
      await redirect('list', resource)
    } else {
      await navigate(-1)
    }
  }
  return handleRedirection
}

export const useRunResourceAction = ({ action, actionName, data = {}, id, onSuccess, ...props }) => {
  const notify = useNotify()
  const refresh = useRefresh()
  const translate = useTranslate()

  const resource = useResourceContext(props)
  const [runAction, { isLoading }] = useApi(`/${resource}/${id}/actions`, {
    method: 'POST',
    body: JSON.stringify({ action, ...data }),
    onSuccess: () => {
      onSuccess()
      notify(`resources.${resource}.actions.${actionName}.success`)
      refresh()
    },
    onFailure: (error) => {
      const { code, message } = getErrorDataFromError(error)
      const notification = ERROR_MESSAGES[code]
        ? translate(ERROR_MESSAGES[code])
        : translate('mymove.errors.errorWithMessage', { message })
      notify(notification, { type: 'error' })
    },
  })

  return [runAction, isLoading]
}
