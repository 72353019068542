import { useGetList } from 'react-admin'

import config from './config'
import { SYSTEM_PERMISSION_READ } from '../../config/permissions'
import { useResourcePermissions } from '../../domain/permissions'

export const useGetOrganisations = ({ pagination }) => {
  const [hasRead] = useResourcePermissions(config.name, SYSTEM_PERMISSION_READ)
  return useGetList(
    config.name,
    { pagination, filter: config.options.defaultFilterValues, sort: config.options.defaultSort },
    { enabled: hasRead },
  )
}
