import { DateTime, Duration, Settings } from 'luxon'

import { formatUnit, toSentence } from '.'
import env from '../config/env'

Settings.defaultLocale = 'en-GB'
Settings.defaultZoneName = env.TIMEZONE

// Regex to detect if a date string is in "HH:mm:ss" format
export const TIME_REGEX = /^(\d{2}):(\d{2}):(\d{2})$/

export const parseLocalDate = (date) => DateTime.fromSQL(date)

export const parseDateAsDateTime = (date) => {
  let dt
  if (DateTime.isDateTime(date)) {
    dt = date
  } else if (date instanceof Date) {
    dt = DateTime.fromJSDate(date)
  } else if (date && typeof date.indexOf === 'function' && date.indexOf('T') > -1) {
    dt = DateTime.fromISO(date)
  } else if (date) {
    const dateShortRegex =
      /^(?:(?:31(\/)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/
    if (dateShortRegex.test(date)) {
      // If date matches dd/MM/yyyy format, we adapt it to match "new Date ()" function's expected format (yyyy-MM-dd)
      // If we don't adapt, day & month values are inverted
      dt = DateTime.fromJSDate(new Date(date.split('/').reverse().join('-')))
    } else {
      dt = DateTime.fromJSDate(new Date(date))
    }
  } else {
    dt = { isValid: false }
  }
  return dt
}

export const parseTimeAsDateTime = (time) => {
  let dt
  if (DateTime.isDateTime(time)) {
    dt = time
  } else if (time && typeof time.indexOf === 'function' && time.indexOf(':') > -1) {
    dt = DateTime.fromISO(time)
  } else {
    dt = { isValid: false }
  }
  return dt
}

export const getRoundedNow = () => DateTime.local().set({ second: 0, millisecond: 0 })
export const getRoundedNowJS = () => getRoundedNow().toJSDate()

export const getDateFromNow = (offset) => {
  const now = getRoundedNow()
  return now.plus(offset).toJSDate()
}

export const getDateBoundsForPeriod = ({
  referenceDate = getRoundedNow(),
  period,
  startOffset = {},
  endOffset = {},
}) => {
  return {
    startDate: referenceDate.startOf(period).minus(startOffset).toJSDate(),
    endDate: referenceDate.endOf(period).plus(endOffset).toJSDate(),
  }
}

const getDateBoundsWithOffset = (offset) => {
  const now = getRoundedNow()
  const startDate = now.minus(offset)
  const endDate = now.plus(offset)
  return [startDate, endDate].map((d) => d.toJSDate())
}

export const getDateWithThirtyMinutesOffset = () => getDateFromNow({ minutes: 30 })
export const getTwoHoursDateBounds = () => getDateBoundsWithOffset({ hours: 1 })
export const getTwoMonthsDateBounds = () => getDateBoundsWithOffset({ months: 1 })

export const formatDate = (value, format = DateTime.DATE_SHORT) => {
  const dt = parseDateAsDateTime(value)
  return dt.isValid ? dt.toLocaleString(format) : 'n/a'
}

export const formatTime = (value, format = DateTime.TIME_24_WITH_SECONDS) => {
  const dt = parseTimeAsDateTime(value)
  return dt.isValid ? dt.toLocaleString(format) : 'n/a'
}

export const formatDateTime = (value) => {
  return formatDate(value, DateTime.DATETIME_SHORT)
}

export const formatDateTimeForExport = (value) => {
  const dt = parseDateAsDateTime(value)
  return dt.isValid ? dt.toFormat('dd/MM/yyyy HH:mm') : ''
}

export const formatDuration = (value, source = 'minutes') => {
  const dur = Duration.fromObject({ years: 0, days: 0, hours: 0, minutes: 0, [source]: value }).normalize()
  const words = Object.entries(dur.toObject())
    .map(([unit, value]) =>
      value > 0 ? `${value} ${formatUnit(unit.endsWith('s') ? unit.slice(0, -1) : unit, value)}` : null,
    )
    .filter(Boolean)
  return toSentence(words.length > 0 ? words : [`0 ${source.endsWith('s') ? source.slice(0, -1) : source}`])
}

export const getISOFromDateTime = (dt, options = {}) => {
  if (options.offset) {
    dt = dt.plus(options.offset)
  }
  return dt.toISO()
}

export const parseDateAsISO = (value) => {
  let dt = parseDateAsDateTime(value)
  if (!dt.isValid) {
    return value
  }
  return getISOFromDateTime(dt)
}

const applyDateFunc = (func, ...dates) => {
  const dateTimes = dates
    .map((date) => {
      const dt = parseDateAsDateTime(date)
      if (!dt.isValid) {
        return null
      }
      return dt
    })
    .filter(Boolean)
  return func(...dateTimes)
}

export const getMaxDate = (...dates) => {
  const dt = applyDateFunc(DateTime.max, ...dates)
  return dt && dt.isValid ? dt.toJSDate() : null
}

export const getMinDate = (...dates) => {
  const dt = applyDateFunc(DateTime.min, ...dates)
  return dt && dt.isValid ? dt.toJSDate() : null
}

export const validateEndTimeAfterStartTime = (startTime, endTime) => {
  if (startTime && endTime) {
    const minTime = parseTimeAsDateTime(startTime).plus({ minutes: 1 })
    if (parseTimeAsDateTime(endTime) < minTime) {
      return {
        message: 'mymove.validation.time.endAfterStart',
        args: { minTime: formatTime(startTime, DateTime.TIME_24_SIMPLE) },
      }
    }
  }
}

export const validateEndDateAfterStartDate = (startDate, endDate, addedMinutes = 0, removedMinutes = 0) => {
  if (startDate && endDate) {
    const minDate = parseDateAsDateTime(startDate).plus({ minutes: addedMinutes })
    if (parseDateAsDateTime(endDate) < minDate.minus({ minutes: removedMinutes })) {
      return { message: 'mymove.validation.date.endAfterStart', args: { minDate: formatDateTime(minDate) } }
    }
  }
}

export const getWeekDayFromIndex = (index) =>
  [
    'mymove.dates.weekDays.monday',
    'mymove.dates.weekDays.tuesday',
    'mymove.dates.weekDays.wednesday',
    'mymove.dates.weekDays.thursday',
    'mymove.dates.weekDays.friday',
    'mymove.dates.weekDays.saturday',
    'mymove.dates.weekDays.sunday',
  ][index]
