import LanguageIcon from '@mui/icons-material/Language'
import { Dialog, DialogTitle, ListItemText, MenuItem, MenuList, Select } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classnames from 'classnames'
import { useTranslate } from 'ra-core'
import React, { useState } from 'react'
import { MenuItemLink, useLocaleState } from 'react-admin'

import { useCommonStyles } from '../config/theme'

const useStyles = makeStyles((theme) => ({
  languageSwitch: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}))

const languages = [
  { code: 'en', label: 'English', flag: '🇬🇧' },
  { code: 'fr', label: 'Français', flag: '🇫🇷' },
  { code: 'nl', label: 'Nederlands', flag: '🇳🇱' },
]

export const LanguageSwitchMenuItemLink = (props) => {
  const [languagesDialogOpen, setLanguagesDialogOpen] = useState(false)
  const closeDialog = () => setLanguagesDialogOpen(false)

  const translate = useTranslate()

  const [locale, setLocale] = useLocaleState()
  const switchLanguage = (languageCode) => {
    if (languageCode !== locale) {
      setLocale(languageCode)
    }
    closeDialog()
  }

  return locale ? (
    <>
      <MenuItemLink
        {...props}
        primaryText="mymove.switchLanguage"
        leftIcon={<LanguageIcon />}
        onClick={() => setLanguagesDialogOpen(true)}
      />
      <Dialog open={languagesDialogOpen} onClose={closeDialog}>
        <DialogTitle>{translate('mymove.selectLanguage')}</DialogTitle>
        <MenuList disablePadding>
          {languages.map((language) => {
            const { code, label, flag } = language
            const isCurrent = code === locale
            return (
              <MenuItem key={code} onClick={() => switchLanguage(code)} selected={isCurrent}>
                <ListItemText primary={`${flag} ${label}`} />
              </MenuItem>
            )
          })}
        </MenuList>
      </Dialog>
    </>
  ) : null
}

const LanguageSwitch = () => {
  const commonClasses = useCommonStyles()
  const classes = useStyles()

  const [isLanguageSwitchExpanded, setIsLanguageSwitchExpanded] = useState(false)
  const onLanguageSwitchOpen = () => setIsLanguageSwitchExpanded(true)
  const onLanguageSwitchClose = () => setIsLanguageSwitchExpanded(false)

  const [locale, setLocale] = useLocaleState()
  const switchLanguage = (event) => {
    setLocale(event.target.value.code)
  }

  return locale ? (
    <Select
      value={languages.find((language) => language.code === locale)}
      onOpen={onLanguageSwitchOpen}
      onClose={onLanguageSwitchClose}
      onChange={switchLanguage}
      className={classnames(
        commonClasses.commonSwitchRoot,
        isLanguageSwitchExpanded ? commonClasses.commonSwitchExpandedBorder : null,
        classes.languageSwitch,
      )}
      classes={{ icon: commonClasses.commonSwitchIcon }}
    >
      {languages.map((language) => {
        const { code, flag, label } = language
        return (
          <MenuItem key={code} value={language}>
            {flag} {label}
          </MenuItem>
        )
      })}
    </Select>
  ) : null
}

export default LanguageSwitch
