import { Typography } from '@mui/material'
import { alpha } from '@mui/material/styles'
import { useTheme } from '@mui/styles'
import get from 'lodash/get'
import { useTranslate } from 'ra-core'
import { Filter, List, ReferenceField, useListContext } from 'react-admin'

import config from './config'
import bookingsExporter from './exporter'
import { BookingPeriodFilter } from './filter'
import BookingFormLayout, { transformValues } from './form'
import { useCanEditBookings } from './hooks'
import AdvancedBooleanFilter from '../../components/AdvancedBooleanFilter'
import { AdvancedDatesFilter } from '../../components/AdvancedDatesFilter'
import AdvancedTextField from '../../components/AdvancedTextField'
import BookingConsumedField from '../../components/BookingConsumedField'
import CompactList from '../../components/CompactList'
import DateTooltipField from '../../components/DateTooltipField'
import PeriodField from '../../components/PeriodField'
import PriceField from '../../components/PriceField'
import ReferenceValue from '../../components/ReferenceValue'
import StaticText from '../../components/StaticText'
import StatusField from '../../components/StatusField'
import UserFullNameField from '../../components/UserFullNameField'
import VehicleIconField from '../../components/VehicleIconField'
import VehicleNameField from '../../components/VehicleNameField'
import {
  BOOKING_BILLING_TYPE_SOURCE,
  BOOKING_BILLING_TYPES,
  BOOKING_ERA_CURRENT,
  BOOKING_ERAS,
} from '../../config/bookings'
import { SYSTEM_PERMISSION_READ } from '../../config/permissions'
import { darkTheme, useCommonStyles } from '../../config/theme'
import { isBookingEditable, wasBookingCancelled } from '../../domain/bookings'
import { useResourcePermissions } from '../../domain/permissions'
import { getStatusStyles } from '../../domain/statuses'
import { isAllowed } from '../../utils'
import { useCurrentAccountSelectors } from '../accounts/hooks'
import { AdvancedCreateButton } from '../common/create'
import { AdvancedEditInDialogButton } from '../common/edit'
import { GenericListLayout, ListActions, ListDatagrid } from '../common/list'
import HubsFilter from '../hubs/filter'
import OrganisationField from '../organisations/field'
import OrganisationsFilter from '../organisations/filter'
import usersConfig from '../users/config'
import UserReferenceInput from '../users/input'
import VehiclesFilter from '../vehicles/filter'

const BookingsFilters = (props) => {
  const [hasReadForUsers] = useResourcePermissions(usersConfig.name, SYSTEM_PERMISSION_READ)
  return (
    <Filter {...props}>
      <OrganisationsFilter alwaysOn />
      <HubsFilter alwaysOn />
      {hasReadForUsers && <UserReferenceInput alwaysOn validate={null} />}
      <VehiclesFilter alwaysOn />
      <AdvancedBooleanFilter source="cancelled" alwaysOn />
      <BookingPeriodFilter alwaysOn />
      <AdvancedDatesFilter alwaysOn />
    </Filter>
  )
}

const bookingRowSx = (record) => {
  const wasCancelled = wasBookingCancelled(record)
  const isCurrent = !wasCancelled && get(record, 'era') === BOOKING_ERA_CURRENT
  return {
    opacity: wasCancelled ? 0.4 : 1,
    backgroundColor: isCurrent ? alpha(darkTheme.palette.secondary.main, 0.2) : 'none',
    '&:hover': {
      backgroundColor: isCurrent ? `${alpha(darkTheme.palette.secondary.main, 0.3)} !important` : undefined,
    },
  }
}

export const BookingsListLayout = ({ excluded = [], isFullList = false, ...props }) => {
  const canEditBookings = useCanEditBookings()
  const { hasSingleOrganisation, hasSingleHub } = useCurrentAccountSelectors()
  const [hasReadForUsers] = useResourcePermissions(usersConfig.name, SYSTEM_PERMISSION_READ)

  const commonClasses = useCommonStyles()
  const theme = useTheme()
  const translate = useTranslate()

  const { filterValues } = useListContext()
  const isFilteredByOrganisation = Boolean(filterValues.organisation_id)
  const isFilteredByHub = Boolean(filterValues.hub_id)
  const isFilteredByUser = Boolean(filterValues.user_id)
  const isFilteredByVehicle = Boolean(filterValues.vehicle_id)

  const shouldDisplayEditButton = (record) => canEditBookings && isBookingEditable(record)
  const shouldDisplayUsers = hasReadForUsers && isAllowed(excluded, 'users') && !isFilteredByUser
  const shouldDisplayVehiclesAttributes = isAllowed(excluded, 'vehicles') && !isFilteredByVehicle

  return (
    <GenericListLayout
      compactListLayout={
        <CompactList
          linkType="show"
          itemSx={bookingRowSx}
          icon={
            <ReferenceValue reference="vehicles" target={(v) => <VehicleIconField record={v} />} emptyText={null} />
          }
          iconBadgeColor={(record) => getStatusStyles(record.status, theme).mainColor}
          isResourceEditable={shouldDisplayEditButton}
          title={() => (
            <>
              <Typography component="span" variant="body1" className={commonClasses.inline} color="textPrimary">
                <ReferenceValue reference="organisations" />
              </Typography>
              <Typography component="span" variant="body1" className={commonClasses.inline} color="textSecondary">
                {' • '}
                {shouldDisplayUsers ? <ReferenceValue reference="users" /> : <ReferenceValue reference="vehicles" />}
              </Typography>
            </>
          )}
          body={() => (
            <>
              <StaticText>{translate('resources.hubs.name', 1)}: </StaticText>
              <ReferenceValue reference="hubs" />
              <br />
              {shouldDisplayUsers && isAllowed(excluded, 'vehicles') && (
                <>
                  <StaticText>{translate('resources.vehicles.name', 1)}: </StaticText>
                  <ReferenceValue reference="vehicles" />
                  <br />
                </>
              )}
              <StaticText>{'resources.bookings.fields.era'}: </StaticText>
              <AdvancedTextField source="era" map={BOOKING_ERAS} />
              <br />
              <PeriodField startedOnSource="effective_started_on" endedOnSource="effective_ended_on" addTime />
              <br />
              <StaticText>{'resources.bookings.fields.price'}: </StaticText>
              <PriceField source="amount" />
            </>
          )}
        />
      }
      regularListLayout={
        <ListDatagrid rowSx={bookingRowSx}>
          <StatusField />
          {!hasSingleOrganisation && !isFilteredByOrganisation && <OrganisationField />}
          {!hasSingleHub && !isFilteredByHub && <ReferenceField source="hub_id" reference="hubs" link={false} />}
          {shouldDisplayUsers && (
            <ReferenceField reference="users" source="user_id" link="show">
              <UserFullNameField showFirstNameInitial={isFullList} />
            </ReferenceField>
          )}
          {shouldDisplayVehiclesAttributes &&
            [
              <ReferenceValue
                label="resources.vehicles.fields.type"
                reference="vehicles"
                target={(v) => <VehicleIconField color="textSecondary" record={v} />}
              />,
              <ReferenceField
                reference="vehicles"
                source="vehicle_id"
                link={false}
                label="resources.vehicles.fields.model"
              >
                <VehicleNameField />
              </ReferenceField>,
              <ReferenceField
                source="vehicle_id"
                sortBy="vehicle.designation"
                reference="vehicles"
                label="resources.vehicles.fields.designation"
                link="show"
              >
                <AdvancedTextField source="designation" />
              </ReferenceField>,
            ].map((field, index) => ({ ...field, key: index }))}
          <AdvancedTextField source={BOOKING_BILLING_TYPE_SOURCE} map={BOOKING_BILLING_TYPES} />
          <PriceField source="amount" />
          <BookingConsumedField />
          <AdvancedTextField source="era" map={BOOKING_ERAS} />
          <DateTooltipField source="effective_started_on" addTime />
          <DateTooltipField source="effective_ended_on" addTime />
          <AdvancedEditInDialogButton condition={shouldDisplayEditButton} transform={transformValues}>
            <BookingFormLayout />
          </AdvancedEditInDialogButton>
        </ListDatagrid>
      }
      {...props}
    />
  )
}

export default () => (
  <List
    empty={false}
    sort={config.options.defaultSort}
    filters={<BookingsFilters />}
    filterDefaultValues={config.options.defaultFilterValues}
    exporter={bookingsExporter}
    actions={
      <ListActions hasExport>
        <AdvancedCreateButton transform={transformValues}>
          <BookingFormLayout />
        </AdvancedCreateButton>
      </ListActions>
    }
  >
    <BookingsListLayout isFullList />
  </List>
)
